import { takeLatest, call, select, take, fork } from 'redux-saga/effects';
import { MoviesActionType } from './MoviesActionType';
import { SagaIterator } from 'redux-saga';
import { callApi, RequestError } from '../../sagas/APICallSaga';
import { AxiosResponse } from 'axios';
import {
    MoviesAction,
    createFetchMoviesSuccessAction,
    createFetchMoviesErrorAction,
    FETCHED_PAGE_SIZE,
} from './MoviesActions';
import { AppState } from '../../store/reducers';
import map from 'lodash/map';
import get from 'lodash/get';
import { PaginationData, SortOption } from '../../domain/listings';
import { saveAs } from 'file-saver';
import { AnyAction } from 'redux';
import { Movie } from '../../domain/work';
import { i18n } from '../../i18n';
import { getMovieDptCrewFullNamesList } from '../../domain/utils/MovieUtils';
import { formatDuration } from '../../utils/DateAndTimeUtils';
import dayjs from 'dayjs';
import { uniq } from 'lodash';

function* selectParams(): SagaIterator {
    let params: Record<string, string[] | string | number> = yield select(
        (state: AppState): Record<string, string[]> => state.filter.filters
    );

    const searchTerm = yield select((state: AppState): string => state.filter.searchTerm);
    if (searchTerm) {
        params = {
            ...params,
            // eslint-disable-next-line @typescript-eslint/camelcase
            q_text: searchTerm,
        };
    }
    const sort = yield select((state: AppState): SortOption | undefined => state.movies.sort);
    if (sort) {
        params = {
            ...params,
            sort,
        };
    }
    return params;
}

function* fetchMovies(action: MoviesAction): SagaIterator {
    const params = yield call(selectParams);
    const pagination = yield select((state: AppState): PaginationData | undefined => state.movies.pagination);

    let pageSize = FETCHED_PAGE_SIZE;
    let currentPage = action.requestedPage || 0;
    if (pagination && pagination.currentPage > currentPage) {
        // Recover history page size if browser back button is used by user
        pageSize = FETCHED_PAGE_SIZE * (pagination.currentPage + 1);
        currentPage = pagination.currentPage;
    }

    yield call(callApi, {
        endpoint: {
            url: `search-movies`,
            params: {
                ...params,
                size: pageSize,
                page: action.requestedPage,
            },
        },
        onSuccess: (res: AxiosResponse): MoviesAction =>
            createFetchMoviesSuccessAction(
                get(res, 'data._embedded.movies', []),
                {
                    currentPage: currentPage,
                    pageSize: action.pageSize || FETCHED_PAGE_SIZE,
                    totalCount: parseInt(res.headers['x-total-count']),
                    pageCount: parseInt(res.headers['x-page-count']),
                },
                res.data._facets
            ),
        onError: createFetchMoviesErrorAction,
    });
}

function s2ab(s: any): ArrayBuffer {
    const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    const view = new Uint8Array(buf); //create uint8array as viewer
    for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff; //convert to octet
    }
    return buf;
}

function getRightsStringForExport(movie: Movie): string {
    let rights;
    const contractStartingDate = get(movie, '_links.contract.extra.startingAt'),
        contractEndingDate = get(movie, '_links.contract.extra.endingAt');
    if (!contractStartingDate || !contractEndingDate) {
        rights = i18n.t('movies:noValidContractFound');
    } else {
        if (dayjs().isAfter(dayjs(contractStartingDate)) && dayjs().isBefore(dayjs(contractEndingDate))) {
            rights = i18n.t('movies:rightsValid');
        } else if (dayjs().isBefore(dayjs(contractStartingDate))) {
            rights = i18n.t('movies:rightsAvailableSoon');
        } else {
            rights = i18n.t('movies:rightsExpired');
        }
    }
    return rights;
}

function prepareMovieForExport(movie: Movie): object {
    return {
        [i18n.t('movies:title')]: movie.title,
        [i18n.t('movies:director')]: getMovieDptCrewFullNamesList(movie, 'directors'),
        [i18n.t('movies:catalog')]: map(movie.catalogs, 'name').join(', '),
        [i18n.t('movies:category')]: get(movie, 'category.name', ''),
        [i18n.t('movies:genre')]: map(movie.genres, 'name').join(', '),
        [i18n.t('movies:duration')]: formatDuration(get(movie, 'duration')),
        [i18n.t('movies:productionYear')]: get(movie, 'productionYear', ''),
        [i18n.t('movies:languages')]: map(movie.originalAudioLanguages, 'name').join(', '),
        [i18n.t('movies:videoTypes')]: uniq(map(get(movie, 'videoTypes'), 'name')).join(', '),
        [i18n.t('movies:productionCountries')]: map(movie.productionCountries, 'name').join(', '),
        [i18n.t('movies:shootingCountries')]: map(movie.shootingCountries, 'name').join(', '),
        [i18n.t('movies:directorCountries')]: map(get(movie, 'directors'), 'country.name')
            .filter(Boolean)
            .join(', '),
        [i18n.t('movies:rights')]: getRightsStringForExport(movie),
        [i18n.t('movies:contractEndingDate')]: dayjs(get(movie, '_links.contract.extra.endingAt')).format('MM/DD/YYYY'),
    };
}

function* exportToXLS(): SagaIterator {
    const params = yield call(selectParams);
    const totalResults = yield select((state: AppState): number =>
        state.movies.pagination ? state.movies.pagination.totalCount : 0
    );

    yield fork(callApi, {
        endpoint: {
            url: `search-movies`,
            params: {
                ...params,
                size: totalResults,
                page: 0,
            },
        },
        onSuccess: (res: AxiosResponse): AnyAction => {
            return { type: MoviesActionType.EXPORT_RESULTS_READY, payload: get(res, 'data._embedded.movies', []) };
        },
        onError: (_error: RequestError): AnyAction => {
            // console.error(error); // FIXME: Use a proper logging lib
            return { type: 'MOVIES/EXPORT_ERROR' };
        },
    });

    const exportAction = yield take(MoviesActionType.EXPORT_RESULTS_READY);
    const results = exportAction.payload.map(prepareMovieForExport);

    import(/*webpackChunkName: 'xlsx', webpackPrefetch: true */ 'xlsx')
        .then((XLSX: any): void => {
            const wb = XLSX.utils.book_new();
            wb.Props = {
                Title: i18n.t('movies:exportTitle'),
                Author: 'IFcinéma',
                CreatedDate: new Date(),
            };
            XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(results), 'Export');
            const wbOut = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
            saveAs(new Blob([s2ab(wbOut)], { type: 'application/octet-stream' }), i18n.t('movies:exportFilename'));
        })
        .catch(/*console.error*/); // FIXME: USe a proper logging lib.
}

export default function* moviesSaga(): SagaIterator {
    yield takeLatest([MoviesActionType.FETCH_MOVIES, MoviesActionType.UPDATE_SORTING], fetchMovies);
    yield takeLatest(MoviesActionType.EXPORT_TO_XLS, exportToXLS);
}
