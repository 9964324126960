import { MoviesActionType } from './MoviesActionType';
import { Movie } from '../../domain/work';
import { FetchAction } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';
import { SearchFacetsMap, SearchFacet, SearchFacetName } from '../../domain/search';
import { PaginationData, PaginationAction, SortingAction, SortOption } from '../../domain/listings';

export const FETCHED_PAGE_SIZE = 20;

export interface MoviesAction extends FetchAction<MoviesActionType, Movie[]>, PaginationAction<MoviesActionType> {
    facets?: SearchFacetsMap<SearchFacet>;
}

export interface FetchMoviesAction extends MoviesAction {
    sort: SortOption;
}

export function createFetchMoviesAction(sort: SortOption, requestedPage: number = 0): FetchMoviesAction {
    return { type: MoviesActionType.FETCH_MOVIES, requestedPage, pageSize: FETCHED_PAGE_SIZE, sort };
}

// FIXME : probably shouldn't be hardcoded here. We should be able to retrieve an ordered list of vocabulary entries.
const durationTypes = ['feature-film', 'short-film', 'series'];
const videoFormats = ['hd-plus', 'hd', 'sd'];
const videoTypes = [
    'telechargement',
    'dcp',
    'blu-ray',
    'dvd',
    '35-mm',
    '16-mm',
    'beta-num',
    'betacam-sp',
    'theatre-filme',
    'realite-virtuelle',
];

function sortFacetTerms(facet: SearchFacet): SearchFacet {
    return {
        ...facet,
        terms: facet.terms.sort((a, b): number => {
            if (a.name === b.name) {
                return 0;
            }
            if (facet.name === SearchFacetName.PRODUCTION_PERIODS) {
                if (a.name.startsWith('before')) {
                    return 1;
                }
                if (b.name.startsWith('before')) {
                    return -1;
                }
                if (a.name.startsWith('after')) {
                    return -1;
                }
                if (b.name.startsWith('after')) {
                    return 1;
                }
                return a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1;
            }
            if (facet.name === SearchFacetName.DURATION_TYPES) {
                return durationTypes.indexOf(a.value) > durationTypes.indexOf(b.value) ? 1 : -1;
            }
            if (facet.name === SearchFacetName.VIDEOTYPES) {
                return videoTypes.indexOf(a.value) > videoTypes.indexOf(b.value) ? 1 : -1;
            }
            if (facet.name === SearchFacetName.VIDEOFORMATS) {
                return videoFormats.indexOf(a.value) > videoFormats.indexOf(b.value) ? 1 : -1;
            }
            if (a.name && b.name) {
                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
            }
            return 0;
        }),
    };
}

function sortAndFilterFacets(facets: SearchFacetsMap<SearchFacet>): SearchFacetsMap<SearchFacet> {
    const searchFacetNames = Object.values(SearchFacetName);
    const orderedFacets: SearchFacetsMap<SearchFacet> = {};
    const orderedKeys = Object.keys(facets).sort((facetA: string, facetB: string): number => {
        const indexA = searchFacetNames.indexOf(facetA as SearchFacetName),
            indexB = searchFacetNames.indexOf(facetB as SearchFacetName);
        if (indexA === indexB) {
            return 0;
        }
        return indexA > indexB ? 1 : -1;
    });
    orderedKeys.forEach((facetName: string): void => {
        const facet = facets[facetName as SearchFacetName];
        if (facet !== undefined && searchFacetNames.includes(facet.name)) {
            orderedFacets[facetName as SearchFacetName] = sortFacetTerms(facet);
        }
    });
    return orderedFacets;
}

export function createFetchMoviesSuccessAction(
    movies: Movie[],
    pagination: PaginationData,
    facets: SearchFacetsMap<SearchFacet>
): MoviesAction {
    return {
        type: MoviesActionType.FETCH_MOVIES_SUCCESS,
        payload: movies,
        pagination,
        facets: sortAndFilterFacets(facets),
    };
}

export function createFetchMoviesErrorAction(error: RequestError): MoviesAction {
    return {
        type: MoviesActionType.FETCH_MOVIES_ERROR,
        payload: error,
    };
}

export function createUpdateMoviesSortingAction(sort: SortOption): SortingAction<MoviesActionType> {
    return {
        type: MoviesActionType.UPDATE_SORTING,
        sort,
    };
}

export function createExportToXLSAction(): MoviesAction {
    return { type: MoviesActionType.EXPORT_TO_XLS };
}
