import { MoviesActionType } from './MoviesActionType';
import { AnyAction } from 'redux';
import { Movie } from '../../domain/work';
import { FetchState } from '../Fetch';
import { Paginable, Sortable, PaginationAction } from '../../domain/listings';
import { FetchMoviesAction } from './MoviesActions';

export interface MoviesState extends FetchState<Movie[]>, Paginable, Sortable {
    isRetrievingExportsResults: boolean;
}
export const initialState: MoviesState = {
    isLoading: false,
    isPaginating: false,
    isSorting: false,
    isRetrievingExportsResults: false,
};

export const moviesReducer = (state: MoviesState = initialState, action: AnyAction): MoviesState => {
    switch (action.type) {
        case MoviesActionType.FETCH_MOVIES:
            return {
                ...state,
                isLoading: true,
                isPaginating: (action as PaginationAction<MoviesActionType>).requestedPage! > 0,
                sort: (action as FetchMoviesAction).sort,
            };

        case MoviesActionType.FETCH_MOVIES_SUCCESS:
            let data = action.payload;
            if (
                action.pagination !== undefined &&
                action.pagination.currentPage > 0 &&
                state.data !== undefined &&
                state.data.length === action.pagination.currentPage * action.pagination.pageSize
            ) {
                data = state.data.concat(data);
            }
            return {
                ...state,
                isLoading: false,
                isPaginating: false,
                isSorting: false,
                data,
                error: undefined,
                pagination: action.pagination,
            };

        case MoviesActionType.FETCH_MOVIES_ERROR:
            return {
                ...state,
                isLoading: false,
                isPaginating: false,
                isSorting: false,
                data: undefined,
                error: action.payload,
            };

        case MoviesActionType.UPDATE_SORTING:
            return {
                ...state,
                isLoading: true,
                isSorting: true,
                sort: action.sort,
            };

        case MoviesActionType.EXPORT_TO_XLS:
            return {
                ...state,
                isRetrievingExportsResults: true,
            };

        case MoviesActionType.EXPORT_RESULTS_READY:
            return {
                ...state,
                isRetrievingExportsResults: false,
            };

        default:
            return state;
    }
};
