import { Action } from 'redux';
import { FilterActionType } from './FilterActionType';
import { SearchFacet } from '../../domain/search';

export type FilterAction = Action<FilterActionType>;

export interface FilterSetAction extends FilterAction {
    filters: Record<string, string[]>;
    searchTerm?: string;
}

export interface FilterClearAction extends FilterAction {
    exclude: string[];
}

export interface FilterChangeAction extends FilterAction {
    facet: SearchFacet;
    isChecked: boolean;
    value: string;
}

export function createClearFiltersAction(exclude: string[]): FilterClearAction {
    return { type: FilterActionType.CLEAR_FILTERS, exclude };
}

/**
 * Create a Redux Action to update the currently applied filters
 * @param facetName
 * @param isChecked Whether the filter should be added (true) or removed (false)
 * @param value the filter value
 */
export function createFilterChangeAction(facet: SearchFacet, isChecked: boolean, value: string): FilterChangeAction {
    return {
        type: FilterActionType.FILTER_CHANGE,
        facet,
        isChecked,
        value,
    };
}

/*
 * Create a Redux Action to set filters (retrieved from the query string)
 * @param filters the filters to set
 * @param searchTerm the value of the q_text parameter
 */
export function createFilterSetAction(filters: Record<string, string[]>, searchTerm?: string): FilterSetAction {
    return {
        type: FilterActionType.SET_FILTERS,
        filters,
        searchTerm,
    };
}
