import { Cycle } from './editorial';
import { Movie } from './work';

export type SuggestedSearchTerms = string;

export interface SearchSuggestions {
    terms: SuggestedSearchTerms[];
    movies: Movie[];
    cycles: Cycle[];
}

export interface EmbeddedSearchSuggestions {
    _embedded: SearchSuggestions;
}

export interface SearchEditorialSuggestion {
    title: string;
}

export enum SearchFacetName {
    CATALOGS = 'catalogs',
    CATEGORIES = 'categories',
    GENRES = 'genres',
    THEMES = 'themes',
    VIDEOTYPES = 'videoTypes',
    VIDEOFORMATS = 'videoFormats',
    DIRECTOR_COUNTRIES = 'directorCountries',
    SUBTITLE_LANGUAGES = 'subtitleLanguages',
    AUDIO_LANGUAGES = 'audioLanguages',
    PRODUCTION_PERIODS = 'productionPeriods',
    EXCLUDED_COUNTRIES = 'excludedCountries',
    DURATION_TYPES = 'durationTypes',
    RIGHTS_ONGOING = 'rightsOngoing',
    EDCUATIONAL_FILES = 'educationalFiles',
    // PRODUCTION_YEARS = 'productionYears',
}

export type SearchFacetsMap<T> = Partial<Record<SearchFacetName, T>>;

export interface SearchFacetTerm {
    value: string;
    name: string;
    count: number;
}

export interface SearchFacet {
    name: SearchFacetName;
    queryParam: string;
    terms: SearchFacetTerm[];
}
