import { FilterAction, FilterChangeAction, FilterSetAction, FilterClearAction } from './FilterActions';
import { FilterActionType } from './FilterActionType';
import { SearchFacetsMap, SearchFacet } from '../../domain/search';
import pick from 'lodash/pick';
import without from 'lodash/without';
import { MoviesActionType, MoviesAction } from '../movies';

export interface FilterState {
    /**
     * The search facets
     */
    facets?: SearchFacetsMap<SearchFacet>;

    /**
     * The full text search term that has been used
     * to perform the search.
     */
    searchTerm: string;

    /**
     * The currently applied filters
     */
    filters: Record<string, string[]>;
}

export const initialState: FilterState = {
    facets: {},
    filters: {},
    searchTerm: '',
};

export const facetsParams = [
    'q_audio_lang',
    'q_production_year',
    'q_video_type',
    'q_genre',
    'q_catalog',
    'q_video_format',
    'q_director_country',
    'q_subtitle_lang',
    'q_legal_country',
    'q_category',
    'q_production_period',
    'q_duration_type',
    'q_theme',
    'q_rights_ongoing',
    'q_educational_file',
];

function updateFilters(state: FilterState, action: FilterChangeAction): FilterState {
    let newFilters: string[] = state.filters[action.facet.queryParam] || [];
    if (action.isChecked) {
        newFilters = [...newFilters, action.value];
    } else {
        newFilters = without(newFilters, action.value);
    }
    return {
        ...state,
        filters: {
            ...state.filters,
            [action.facet.queryParam]: newFilters,
        },
    };
}

export const filterReducer = (state: FilterState = initialState, action: FilterAction | MoviesAction): FilterState => {
    switch (action.type) {
        case FilterActionType.CLEAR_FILTERS:
            return {
                ...state,
                filters: pick(state.filters, (action as FilterClearAction).exclude),
            };
        case FilterActionType.SET_FILTERS:
            const searchTerm = (action as FilterSetAction).searchTerm || '';
            return {
                ...state,
                searchTerm,
                filters: (action as FilterSetAction).filters,
            };
        case FilterActionType.FILTER_CHANGE:
            return updateFilters(state, action as FilterChangeAction);
        case MoviesActionType.FETCH_MOVIES_SUCCESS:
            return {
                ...state,
                facets: (action as MoviesAction).facets,
            };
        default:
            return state;
    }
};
