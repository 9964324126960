import { SagaIterator } from 'redux-saga';
import { takeLatest, select, call } from 'redux-saga/effects';
import { FilterActionType, FilterAction } from '../filter';
import { AppState } from '../../store/reducers';
import { stringify } from 'qs';
import { Router } from '../../i18n';
import includes from 'lodash/includes';
import pickBy from 'lodash/pickBy';
import { facetsParams } from './FilterStateReducer';
import { PaginationData, SortOption } from '../../domain/listings';
import { MoviesActionType } from '../movies';
import { FilterClearAction } from './FilterActions';

const removeFiltersFromParams = (
    params: Record<string, string | string[]>,
    exclude: string[]
): Record<string, string | string[]> =>
    pickBy(
        params,
        (_value: string | string[], param: string): boolean =>
            includes(exclude, param) || !includes(facetsParams, param)
    );

const getUpdatedPath = (params: Record<string, string | string[]>): string =>
    `${window.location.pathname.substring(3)}?${stringify(params, { indices: false })}`;

function* updateURL(action: FilterAction): SagaIterator {
    if (typeof window === 'object') {
        const sort = yield select((state: AppState): SortOption | undefined => state.movies.sort);
        const pagination = yield select((state: AppState): PaginationData | undefined => state.movies.pagination);
        pagination.currentPage = 0;
        let params = yield select((state: AppState): Record<string, string[]> => state.filter.filters);
        if (action.type === FilterActionType.CLEAR_FILTERS) {
            params = removeFiltersFromParams(params, (action as FilterClearAction).exclude);
        } else {
            const searchTerm = yield select((state: AppState): string | undefined =>
                state.filter.searchTerm.length > 0 ? state.filter.searchTerm : undefined
            );
            // eslint-disable-next-line @typescript-eslint/camelcase
            params = { ...params, q_text: searchTerm, sort, pagination };
        }

        const as = getUpdatedPath(params);
        const actualPath = as.replace(/catalog\/(fr|afr|alt)/, 'movies');
        yield call(Router.push, actualPath, as, { shallow: true });
    }
}

export default function* filterSaga(): SagaIterator {
    yield takeLatest(
        [FilterActionType.FILTER_CHANGE, FilterActionType.CLEAR_FILTERS, MoviesActionType.UPDATE_SORTING],
        updateURL
    );
}
